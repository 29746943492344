<template>
  <div>
    <!-- 是否退出VR带看提示弹窗 -->
    <div class="quit_hint" v-if="showHintPop === 1">
      <div class="hint_text_box">
        <h4 class="quit_hint_text">是否确定退出VR真人带看?</h4>
      </div>
      <div class="btn_box">
        <div class="quit_hint_ash" @click="confirmQuitLookFunc">确定</div>
        <div class="quit_hint_ash quit_hint_bule" @click="closePop">再看看</div>
      </div>
    </div>

    <!-- 营销师忙线，切换营销师弹窗 -->
    <div class="quit_hint title_height" v-if="showHintPop === 2">
      <div class="hint_text_box">
        <h4 class="quit_hint_text">营销师忙线</h4>
        <h4 class="quit_hint_text">是否切换另外的营销师</h4>
      </div>
      <div class="btn_box">
        <div class="quit_hint_ash" @click="closeCutUser">不用了</div>
        <div class="quit_hint_ash quit_hint_bule" @click="cutUserFunc">
          切换营销师
        </div>
      </div>
    </div>

    <!-- 确定离开当前场景提示框 -->
    <div class="quit_hint" v-if="showHintPop === 3">
      <div class="hint_text_box">
        <h4 class="quit_hint_text">您确定离开当前场景?</h4>
      </div>
      <div class="btn_box">
        <div class="quit_hint_ash" @click="confirmQuitSceneFunc">确定</div>
        <div class="quit_hint_ash quit_hint_bule" @click="closePop">再看看</div>
      </div>
    </div>

    <!-- 已退出远程带看提示框 -->
    <div class="quit_hint title_height" v-if="showHintPop === 4">
      <div class="hint_text_box">
        <h4 class="quit_hint_text">提示信息</h4>
        <h4 class="quit_hint_text" style="font-weight: normal; margin-top: 4px">
          <!-- 您已退出远程带看！ -->
          {{ quitText }}
        </h4>
      </div>
      <div class="btn_box">
        <div class="quit_hint_ash quit_hint_bule" @click="closePop">确定</div>
      </div>
    </div>

    <!-- 房间已解散提示框 -->
    <div class="quit_hint title_height" v-if="showHintPop === 5">
      <div class="hint_text_box">
        <h4 class="quit_hint_text">提示信息</h4>
        <h4 class="quit_hint_text" style="font-weight: normal; margin-top: 4px">
          房间已解散！
        </h4>
      </div>
      <div class="btn_box">
        <div class="quit_hint_ash quit_hint_bule" @click="closePop">确定</div>
      </div>
    </div>

    <!-- 二维码图片信息 -->
    <div
      class="code-pop"
      @click.self="showHintPop = 0"
      v-if="showHintPop === 6"
    >
      <div class="pop-content">
        <div class="logo-box">
          <img src="@/assets/img/1.jpg" alt="" srcset="" />
        </div>
        <div class="company-info">
          <div class="info-box">
            <h4>重庆鲸目科技有限公司</h4>
            <img src="" alt="" srcset="" /> <span>4006851686</span>
            <p>鲸目科技有限公司</p>
          </div>
          <div class="code-img">
            <vue-qr
              :text="infoObj.codeUrl"
              :margin="0"
              colorDark="#000"
              colorLight="#fff"
              :logoScale="0.3"
              :size="200"
            ></vue-qr>
            <!-- <vue-qr :text="info.codeUrl" :margin="0" colorDark="#f67b29" colorLight="#fff" :logoSrc="downloadData.icon + '?cache'" :logoScale="0.3" :size="200"></vue-qr> -->
            <!-- <img src="@/assets/img/3.jpg" alt="" srcset="" /> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 网络中断提示框 -->
    <div class="quit_hint title_height" v-if="showHintPop === 7">
      <div class="hint_text_box">
        <h4 class="quit_hint_text">提示信息</h4>
        <h4 class="quit_hint_text" style="font-weight: normal; margin-top: 4px">
          连接断开！
        </h4>
      </div>
      <div class="btn_box">
        <div class="quit_hint_ash quit_hint_bule" @click="closePop">确定</div>
      </div>
    </div>

    <!-- 未登录提示框 -->
    <div class="quit_hint title_height" v-if="showHintPop === 8">
      <div class="hint_text_box">
        <h4 class="quit_hint_text">提示信息</h4>
        <h4 class="quit_hint_text" style="font-weight: normal; margin-top: 4px">
          请先登录
        </h4>
      </div>
      <div class="btn_box">
        <div class="quit_hint_ash quit_hint_bule" @click="closePop">确定</div>
      </div>
    </div>
  </div>
</template>

<script type="text/javaScript">
import vueQr from 'vue-qr'

export default {
  components: {
    vueQr
  },
  data () {
    return {
      showHintPop: 0, // 0-都不显示 1-显示退出VR带看提示框 2-显示切换营销师弹窗提示框 3-确定离开当前场景提示框 4-已退出远程带看提示框 5 - 房间已解散提示 6 - 展示公司信息及二维码 7-网络中断  8-未登录
      requestUrl: this.$baseUrl.requestUrl, // 接口请求地址
      initScenario: '', //场景 key
      quitText:'',
      infoObj:{
        companyImg: '', // 商家 图片
        codeUrl: '', // 二维码信息
        companyNmae: '', // 商家 名称
        phone: '' , // 商家 电话
        merchantsDesc: '' // 
      }
    }
  },
  methods: {
    closePop(){ // 关闭弹窗
      this.showHintPop = 0
      // return new Promise((resolve)=>{
      //   resolve()
      // })
    },
    init(e){ 
      if(e.type === 'notifyBusy'){ // 营销师忙 - 切换营销师
        this.showHintPop = 2
      }else if(e.type === 'leaveLook'){ // 离开Vr带看
        this.showHintPop = 1
      }else if(e.type === 'quitSuccess'){ // 成功退出远程带看提示
        this.showHintPop = 4
        if(e.key === 'kick-out'){ // 踢出
          this.quitText = '您已被营销师移出房间。'
        }else {
          this.quitText = '您已退出远程带看！'
        }
      }else if(e.type === 'dissolve'){ // 房间解散提示
        this.showHintPop = 5
      }else if(e.type === 'showCode'){ // 显示公司信息及二维码
        this.showHintPop = 6
        this.getmerchantInfo(e.key)
      }else if(e.type === 'network'){ // socket中断提示
        this.showHintPop = 7
      }else if(e.type === 'notLogin'){ // 未登录
        this.showHintPop = 8
      }
    },
    
    cutUserFunc(){ // 切换营销师
      this.closePop()
      // this.$parent.overLookFunc()
      this.$parent.lookStatus = 0      
      this.$parent.showUserList = true
    },
    closeCutUser(){ // 点击不用了-关闭切换营销师弹窗
      this.closePop()
        this.$parent.lookStatus = 0
        this.$parent.isTouchscreen = true
        this.$parent.showSceneUser = true
        this.$parent.websocket.onclose()
    },
    confirmQuitSceneFunc(){ // 点击确定 - 离开当前场景
      this.closePop()
    },

    confirmQuitLookFunc(){ // 退出VR真人带看
      this.closePop()
      this.$parent.wxSendMsgFunc({type:'leave'})
    },
    

    getmerchantInfo(key){ // 根据场景查询商家信息
      this.initScenario = key

      this.$axios.post(
        this.requestUrl + "/api/panorama/queryBusinessByKey",
        JSON.stringify({
          keyNum: this.initScenario
        }), {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((res) => { //成功  
        console.log(res , '根据场景查询商家信息');
        if(res.data.data){
          this.infoObj.companyImg = res.data.data.merchantImageUrl
          // this.infoObj.codeUrl = ''
          this.infoObj.companyNmae = res.data.data.companyName
          this.infoObj.phone = res.data.data.customerServicePhone
          this.infoObj.merchantsDesc = res.data.data.merchantsDesc
        }
        // let obj = res.data.data
      })
      .catch((error) => {
        console.log(error, ' 失败') //失败
      })
    },

  },
  created () {

  },
  mounted () {
  
  }
}
</script>

<style scoped lang="less">
.quit_hint {
  background: #fff;
  width: 275px;
  height: 150px;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 15%;
  margin: auto;
  .hint_text_box {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .quit_hint_text {
      font-size: 17px;
      color: #333333;
      text-align: center;
      margin: 0;
      padding: 0;
    }
  }
  .btn_box {
    border-top: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    .quit_hint_ash {
      background-color: #bcc0cc;
      border-radius: 4px;
      color: #fff;
      width: 105px;
      height: 36px;
      font-size: 15px;
      line-height: 36px;
      margin: 0 7.5px;
    }
    .quit_hint_bule {
      background-color: #0e2ac1;
    }
  }
}
.title_height {
  height: 175px;
  .hint_text_box {
    height: 100px;
  }
}
.code-pop {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(000, 000, 0, 0.3);
  .pop-content {
    width: 75%;
    height: 48%;
    margin: auto;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    .logo-box {
      width: 100%;
      height: 200px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .company-info {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      .info-box {
        text-align: left;
        h4 {
          margin: 2px 0 6px 0;
          color: #000;
          font-size: 14px;
        }
        span {
          font-size: 12px;
          color: #333;
          display: inline-block;
          margin-bottom: 8px;
        }
        p {
          font-size: 12px;
          margin: 0;
          color: #c3c3c3;
        }
      }
      .code-img {
        width: 80px;
        height: 80px;
        margin-left: 20px;
        padding: 4px;
        border: 1px solid #eee;
        border-radius: 2px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
