import baseUrl from '@/utils/baseUrl.js'

var callChilder = (type, obj) => { // 跨域交互请求
  var frame = document.getElementById('vrId');

  // frame.contentWindow.postMessage({
  //   type: type,
  //   res: obj
  // }, 'https://pano.xingyuancheng.com/');
  console.log(type, obj, 'type, obj');
  frame.contentWindow.postMessage({
    type: type,
    res: obj
  }, baseUrl.phonUrl);

};

var getQueryString = (params) => { // 接收URL参数转换方法
  if (window.location.search !== '') {
    var reg = new RegExp("(^|&)" + params + "=([^&]*)(&|$)", "i"); //解析url地址
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null;
  } else {
    var url = window.location.href
    var cs = url.split('?')[1] //获取?之后的参数字符串
    var cs_arr = cs.split('&')//参数字符串分割为数组
    var obj = {} // 接收到的对象参数
    for (var i = 0; i < cs_arr.length; i++) { //遍历数组，拿到json对象
      obj[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
    }
    // return obj[params]
    return decodeURIComponent(obj[params])
  }
}

const handFunc = {
  callChilder,
  getQueryString

}

export default handFunc