<template>
  <div class="max-box">
    <div
      class="VR-box"
      id="iframe-box"
      :style="isTouchscreen ? 'z-index: 0' : 'z-index: -1'"
    >
      <!-- <iframe id="vrId" name="vrId" src="https://pano.xingyuancheng.com/pano/wc9wmwhoc.html?show=1" frameborder="0"
      style=" border: 0px" width='100%' height="100%"></iframe> -->
      <!-- :src="panoramaCode" -->
      <iframe
        id="vrId"
        class="iframe"
        name="vrId"
        :src="iframeSrc"
        frameborder="0"
        style="border: 0px"
        width="100%"
        height="100%"
      ></iframe>
    </div>
    <!-- 左上 / 房间号 -->
    <div class="top_box">
      <span v-show="lookStatus == 2">
        <img
          class="room-text-img"
          src="@/assets/img/vr-icon/room_icon@2x.png"
          style="vertical-align: bottom"
          alt=""
          srcset=""
        />
        <span style="vertical-align: text-bottom; line-height: 16px">
          {{ userID }}</span
        >
        <img
          class="copy-icon"
          @click="copyRoom()"
          src="@/assets/img/copy.png"
          alt=""
          srcset=""
        />
      </span>
    </div>

    <!-- 右上 / 图标 -->
    <div class="right-top-box" v-show="lookStatus == 2">
      <div class="icon-box" v-show="!roamStatus">
        <img
          @click="setMusicStatus(false)"
          v-show="musicStatus"
          src="../assets/img/vr-icon/open-music.png"
          alt=""
          srcset=""
        />
        <img
          @click="setMusicStatus(true)"
          v-show="!musicStatus"
          src="../assets/img/vr-icon/close-music.png"
          alt=""
          srcset=""
        />
      </div>
      <!-- <div class="icon-box" v-show="!roamStatus">
        <img
          v-show="autoStatus"
          src="../assets/img/vr-icon/open-roam.png"
          alt=""
          srcset=""
          @click="setAutoRoaming(false)"
        />
        <img
          v-show="!autoStatus"
          src="../assets/img/vr-icon/close-roam.png"
          alt=""
          srcset=""
          @click="setAutoRoaming(true)"
        />
      </div>
      <div class="icon-box">
        <img
          @click="setRoamStatus(false)"
          v-show="roamStatus"
          src="../assets/img/vr-icon/close-eye.png"
          alt=""
          srcset=""
        />
        <img
          @click="setRoamStatus(true)"
          v-show="!roamStatus"
          src="../assets/img/vr-icon/open-eye.png"
          alt=""
          srcset=""
        />
      </div> -->
    </div>

    <!-- 右侧 / 图标 -->
    <!-- <div class="right-box" v-show="lookStatus == 2">
      <div class="icon-box" v-show="!roamStatus">
        <img
          @click="showCodeFunc()"
          src="../assets/img/vr-icon/code-img.png"
          alt=""
          srcset=""
        />
      </div>
    </div> -->
    <!-- 左上 直播信息 -->
    <div v-if="liveInfo.liveId">
      <div class="vr-pop" :style="showNum == 1 ? 'left:-100%;' : 'left:12px;'">
        <div @click="gotoLive">
          <img class="vr-img" :src="liveInfo.liveCover" />
          <p class="mt10">去围观</p>
        </div>
        <p class="vr-btn-text">
          <img src="../assets/img/play.gif" class="play-icon" />{{
            liveStatuText[liveInfo.liveStatus]
          }}
        </p>
        <div class="rclose" @click="changeBtn(1)"></div>
      </div>
      <div
        class="vr-pop-btn"
        :style="showNum == 2 ? 'left:-100%;' : 'left:0;'"
        @click="changeBtn(2)"
      >
        进入直播
      </div>
    </div>

    <!-- 左下 / 真人带看按钮 -->
    <div class="scene-look-box" v-if="lookStatus == 0" v-show="showSceneUser">
      <button class="scene-look-btn" @click="openUserListFunc">真人带看</button>
    </div>

    <div class="hand_box">
      <!-- 置于底部板块 -->
      <div class="bottom_box">
        <!-- 带看中-营销师信息/及状态 -->
        <div
          class="user_inof_box"
          v-if="lookStatus == 2 && showMarketerInfoScene"
        >
          <div class="user_info">
            <div class="user_img">
              <img
                :src="
                  userInfo.lordCustomerServiceHead.replace(
                    'http://',
                    'https://'
                  )
                "
                alt=""
                srcset=""
              />
            </div>
            <div class="user_name">
              <span class="name_post">{{ userInfo.csName }}</span>
              <i class="cut_line" v-if="userInfo.positionName"></i>
              <span class="name_post">{{ userInfo.positionName }}</span>
              <p>{{ userInfo.showName }}</p>
            </div>
          </div>
          <div class="status_box">
            <div style="display: inline-block">
              <div class="scene_icon">
                <!-- <img
                  @click="
                    wxSendMsgFunc({
                      type: 'voiceStatus',
                      trigger: 'click',
                    })
                  "
                  :src="voiceImg"
                  alt=""
                  srcset=""
                /> -->
                <img
                  v-show="voiceImg == 1"
                  @click="
                    wxSendMsgFunc({
                      type: 'voiceStatus',
                      trigger: 'click',
                    })
                  "
                  src="@/assets/img/voice-open.png"
                  alt=""
                  srcset=""
                />
                <img
                  v-show="voiceImg == 2"
                  @click="
                    wxSendMsgFunc({
                      type: 'voiceStatus',
                      trigger: 'click',
                    })
                  "
                  src="@/assets/img/voice-close2.png"
                  alt=""
                  srcset=""
                />
                <img
                  v-show="voiceImg == 3"
                  @click="
                    wxSendMsgFunc({
                      type: 'voiceStatus',
                      trigger: 'click',
                    })
                  "
                  src="@/assets/img/voice-close3.png"
                  alt=""
                  srcset=""
                />
                <!--  webVoiceStatus == 0 || webVoiceStatus == 1 -->
                <span> {{ voiceImg != 1 ? "取消静音" : "静音" }}</span>
              </div>
            </div>
            <div class="leave_btn" @click="overTimeFunc">
              <p>{{ dateContent }}</p>
              <p>离开</p>
            </div>
          </div>
        </div>
        <!-- 营销师正在赶来 -->
        <div class="user_inof_box user_active_box" v-if="lookStatus == 1">
          <div class="user_info">
            <div class="user_img">
              <img
                :src="
                  userInfo.lordCustomerServiceHead.replace(
                    'http://',
                    'https://'
                  )
                "
                alt=""
                srcset=""
              />
            </div>
            <div>
              <img
                class="connecting_imgs"
                src="@/assets/img/call_size.gif"
                alt=""
              />
              <!-- <span class="connecting_text" style="border:1px solid red">营销师正在赶来的路上...</span> -->
            </div>
          </div>
          <div class="status_box">
            <div class="leave_btn finish_btn" @click="overLookFunc">
              <p>结束</p>
            </div>
          </div>
        </div>

        <!-- 场景/真人带看 -->
        <div
          class="scene_btn_box"
          v-if="lookStatus == 2 && showMarketerInfoScene"
        >
          <div style="display: inline-block">
            <div class="scene_icon" @click="openSceneListFunc">
              <img src="@/assets/img/pop_img.png" alt="" srcset="" />
              <span>场景</span>
            </div>
          </div>
          <span class="scene_btn" @click="invitationJoninFunc">邀请加入</span>
        </div>

        <!-- 场景列表 -->
        <div class="marketing_user scene_box" v-show="showSceneList">
          <img
            class="mark_pop_img"
            src="@/assets/img/pop_head_bj.png"
            alt=""
            srcset=""
            @click="closeSceneListFunc"
          />
          <div class="user_content scene_list">
            <div class="scene_type_box">
              <p
                class="scene_type"
                @click="changeSceneList(item.id)"
                v-for="(item, index) in sceneTypeList"
                :key="index"
                :class="sceneType == item.id ? 'scene_type_active' : ''"
              >
                {{ item.name }}
              </p>
            </div>
            <div class="cover_list">
              <div
                class="cover"
                v-for="item in sceneList"
                :key="item.id"
                @click="cutSceneFunc(item.id)"
              >
                <img :src="item.thumb_url" alt="" srcset="" />
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- 带看营销师列表 -->
        <div class="marketing_user" v-show="showUserList">
          <img
            class="mark_pop_img"
            src="@/assets/img/pop_head_bj.png"
            alt=""
            srcset=""
            @click="closeUserListFunc"
          />
          <div class="user_content">
            <div
              class="user_sink user_inof_box"
              v-for="(item, index) in userList"
              :key="index"
            >
              <div class="user_info">
                <div class="user_img">
                  <img :src="item.lordCustomerServiceHead" alt="" srcset="" />
                  <span class="user_status" v-if="item.onlineStart == 'online'"
                    >在线</span
                  >
                  <span
                    class="user_status red"
                    v-if="item.onlineStart == 'onbusy'"
                    >正忙</span
                  >
                  <span
                    class="user_status off_line"
                    v-if="item.onlineStart == 'offline'"
                    >下线</span
                  >
                </div>
                <div>
                  <div class="mareter-name">
                    <span class="name_post">{{ item.csName }}</span>
                    <!-- <span class="name_post"> <i class="cut_line"></i> {{item.positionName}}</span> -->
                    <img
                      class="dial_img"
                      src="@/assets/img/dial.png"
                      alt=""
                      srcset=""
                    />
                  </div>

                  <div class="mareter-status">
                    <p>{{ item.showName !== "" ? item.showName : "营销师" }}</p>
                    <img
                      class="takeimg"
                      v-show="item.isPlay == 0"
                      src="@/assets/img/takeing-img.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
              <div
                class="status_box request_look_btn"
                @click="startLookFunc(item.userNo)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tiny_hint_pop" v-if="showTinyHint">
      <span>
        {{ tinyHintText }}
      </span>
    </div>

    <HintPop ref="hintPopControl"></HintPop>

    <div class="share-box" v-show="showShareImg" @click="showShareImg = false">
      <img
        @click="showShareImg = false"
        src="https://pfs-xyc-crm.oss-cn-beijing.aliyuncs.com/images/icon/52fad2b13a73ec81979d2a764ab3a8a.png"
        alt=""
        srcset=""
      />
    </div>
  </div>
</template>


<script src="https://cdn.bootcdn.net/ajax/libs/axios/0.21.0/axios.min.js"></script>

<script type="text/javaScript">
import HintPop from "@/components/callPop/callPop";
import { Toast } from "vant";
import handFunc from "@/api/home";

export default {
  components: {
    HintPop,
  },
  data() {
    return {
      socketUrl: this.$baseUrl.socketUrl,
      requestUrl: this.$baseUrl.requestUrl,
      phonUrl: this.$baseUrl.phonUrl + "pano/", // VR场景基准路径

      iframeSrc: "",

      userID: "", // 房间号
      sceneType: "", // 场景类别
      sceneName: "", // 场景名称
      initScenario: "w2v05gk6r", // 当前场景key

      panoramaCode: "", // 场景 全地址
      scenarioId: "", // 当前场景下ID
      marketerNo: "", // 营销师编号
      custName: "", // 用户名
      custUserNo: "", // 用户编号
      headImageUrl: "", // 被带看人头像
      openid: "", // 用户id

      websocket: null,
      timer: 0,
      linkStatus: false, // true - 连接成功

      roamStatus: false, // 清屏开关
      showCode: true, // 是否展示打开二维码图标
      autoStatus: false, // 自动漫游开关
      musicType: 0, // 背景音乐类型
      musicStatus: false, //背景音乐开关
      vrStatus: false, // VR横屏类型
      gravityStatus: false, // 重力感应类型
      angleType: 0, // 视角模式类型

      userInfo: {
        // 营销师信息
        csName: "", // 营销师名称
        positionName: "", // 营销师职位
        lordCustomerServiceHead: "", // 营销师头像
      },

      isTouchscreen: true, // 是否可控屏  false - 不可控屏  true - 可控屏
      lookStatus: 0, // 带看状态 0-未带看  1-营销师在来的路上 2-带看中
      leave: false, // true - 点击离开  false - 在线
      joinRoomTime: null, // 请求带看成功后开始计时 - 收到小程序端回应取消计时
      leaveRoomTime: null, // 请求离开房间时 开始计时 ， 收到小程序回馈取消计时
      voiceRoom: null, // 控制小程序端音频请求 计时
      webVoiceStatus: 2, // 0-全体静音/关闭音频(不可点击) 1-关闭音频(可点击) 2-打开音频 3-全体开麦
      isControlVoice: true, // 是否可以点击麦克风
      // allCloseVoive: 0, // 1 - 全体静音  2 - 全体开麦

      voiceImg: 1, //require('@/assets/img/voice-open.png'), // 麦克风状态图标
      showMarketerInfoScene: true, // 营销师信息及场景是否展示
      showSceneUser: true, // 场景真人带看按钮是否展示
      showUserList: false, // 带看营销师列表是否在展示
      showSceneList: false, // 带看场景列表是否展示
      showTinyHint: false, // 微提示是否展示
      tinyHintText: "", // 微提示文本
      showShareImg: false, // 分享引导图是否展示

      sceneTypeList: [], // 场景类列表
      sceneTotalList: [], // 场景展示列表
      sceneList: [], // 场景展示列表
      merchantCode: "", // 商家编码
      userList: [], // 营销师列表
      trtcUserList: [], // trtc在线列表

      TimeBar: undefined, // 计时器
      dateContent: "00:00",
      insetout: "",
      flage_staue: true, //刚进入房间,
      touchscreenPermissions: false, //是否可以控屏
      liveInfo: {}, //直播信息
      liveStatuText: ["未开播", "直播中", "回放", "结束"],
      showNum: 2,
      userNo: "",
    };
  },
  // directives: {
  //   clickoutside: { // 初始化指令
  //     bind(el, binding, vnode) {
  //       function closeUserListFunc(e) {
  //         // 这里判断点击的元素是否是本身，是本身，则返回
  //         if (el.contains(e.target)) {
  //           return false;
  //         }
  //         // 判断指令中是否绑定了函数
  //         if (binding.expression) {
  //           // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
  //           binding.value(e);
  //         }
  //       }
  //       // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
  //       el.__vueClickOutside__ = closeUserListFunc;

  //       document.addEventListener('click', closeUserListFunc);
  //     },
  //     update() {},
  //     unbind(el, binding) { // 解除事件监听
  //       document.removeEventListener('click', el.__vueClickOutside__);
  //       delete el.__vueClickOutside__;
  //     }
  //   }
  // },
  created() {
    this.userID = handFunc.getQueryString("userID"); // 房间号
    //this.openid = handFunc.getQueryString("openid") // 用户id
    this.openid = handFunc.getQueryString("custUserNo"); //同步更换userNo
    this.initScenario = handFunc.getQueryString("panoramaCode"); // 场景key
    this.getmerchantInfo(); // 获取商家信息
    this.getSceneList(); // 获取场景列表
    this.marketerNo = handFunc.getQueryString("marketerNo"); // 营销师编号 // XYC710523259
    this.custName = handFunc.getQueryString("custName"); // 被带看人名
    this.custUserNo = handFunc.getQueryString("custUserNo"); // 被带看人编号
    this.headImageUrl = handFunc.getQueryString("headImageUrl"); // 被带看人头像
    this.userNo = handFunc.getQueryString("userNo"); // 被带看人头像
    this.getKeynumLive(); //获取直播信息
  },
  methods: {
    gotoLive() {
      let url =
        "https://pro-xyc.xingyuancheng.com/weblive/index.html#/main/" +
        this.liveInfo.liveId;
      if (this.custUserNo) {
        url =
          url +
          "?userNo=" +
          this.custUserNo +
          "&name=" +
          this.custName +
          "&avatar=" +
          this.headImageUrl;
      } else if (this.userNo) {
        url = url + "?userNo=" + this.userNo;
      }
      window.location.href = url;
    },
    changeBtn(num) {
      this.showNum = num;
    },
    takeLookAt() {
      // 解决带看过程中，自带漫游和背景音乐又自己莫名其妙打开
      clearTimeout(this.insetout);
      this.insetout = setTimeout(() => {
        // 关闭漫游
        this.setAutoRoaming(false, 200);
        if (!this.musicStatus) {
          // 关闭背景音乐
          this.setMusicStatus(false);
        }
      }, 500);
    },
    // 监听全景vr中心滑动
    receiveMessage(e) {
      let type = e.data.type;
      var obj = null;
      this.takeLookAt();
      // trtcUserList  在房间中所有人的信息
      if (this.trtcUserList.length && this.trtcUserList.length > 0) {
        for (let i = 0; i < this.trtcUserList.length; i++) {
          let tempUser = this.trtcUserList[i];
          let sendUserNo = tempUser.userID;
          // 左右滑动
          if (type == "setView") {
            obj = {
              data: {
                messageName: type,
                roomCode: this.userID,
                userNo: this.openid,
                fov: e.data.fov,
                v: e.data.v,
                h: e.data.h,
              },
              type: "syncScreen",
              userNo: sendUserNo,
            };
          } else {
            // 切换场景
            if (e.data.arg) {
              var tempdata = JSON.parse(e.data.arg);
              tempdata["messageName"] = type;
              tempdata["roomCode"] = this.userID;
              obj = {
                data: tempdata,
                type: "syncScreen",
                userNo: sendUserNo,
              };
            }
          }
          // 接收到控屏权限
          if (
            obj &&
            this.isTouchscreen == true &&
            this.touchscreenPermissions
          ) {
            if (this.websocket == null) {
              this.initWebSocket();
            }
            // 向app发送坐标
            this.websocket.send(JSON.stringify(obj));
          }
        }
      } else {
        // 发送消息给小程序，让它把房间里面所有人的信息返回来
        var data = {
          userNo: "weixin" + this.openid,
          msg: {
            type: "All-the-people",
          },
        };
        if (this.websocket == null) {
          this.initWebSocket();
        }
        this.websocket.send(JSON.stringify(data));
      }
    },

    openUserListFunc() {
      // 打开带看营销师列表
      this.showUserList = true;
      this.showSceneUser = false;
      this.getUserList();
    },
    closeUserListFunc() {
      // 关闭带看营销师列表
      this.showUserList = false;
      this.showSceneUser = true;
    },
    openSceneListFunc() {
      // 打开场景列表
      this.showSceneList = true;
      this.showSceneUser = false;
      this.showMarketerInfoScene = false;
    },
    closeSceneListFunc() {
      // 关闭场景列表
      this.showSceneList = false;
      this.showSceneUser = true;
      this.showMarketerInfoScene = true;
    },

    hintFunc(str, Time) {
      // 提示进入/退出房间
      this.tinyHintText = str;
      this.showTinyHint = true;
      setTimeout(() => {
        this.showTinyHint = false;
      }, Time || 2000);
    },

    setRoamStatus(status) {
      //设置清屏
      this.roamStatus = status;
    },

    setAutoRoaming(status, Time) {
      // 自动漫游
      // this.autoStatus = true //开启自动漫游
      // this.autoStatus = false //关闭自动漫游
      if (status) {
        this.autoStatus = true;
      } else {
        this.autoStatus = false;
      }
      setTimeout(() => {
        handFunc.callChilder("setAutoRoaming", {
          status: this.autoStatus,
        });
      }, Time || 5000);
    },

    openMarkPoint(type, id) {
      // 打开标注点
      handFunc.callChilder("openLabelPoint", {
        type,
        id,
      });
    },

    closeMarkPoint(type) {
      // 关闭标注点
      handFunc.callChilder("closeLabelPoint", {
        type,
      });
    },

    setMusicStatus(status) {
      // 背景音乐
      this.musicStatus = status;
      handFunc.callChilder("setMusicStatus", {
        type: this.musicType,
        status: this.musicStatus,
      });
    },

    showCodeFunc(state) {
      // 二维码弹窗展示 hintPopControl
      this.$refs.hintPopControl.init({
        type: "showCode",
        key: this.initScenario,
      });
    },

    serVr() {
      // VR横屏
      handFunc.callChilder("serVr", {
        status: this.vrStatus,
      });
      this.vrStatus = !this.vrStatus;
    },

    setGravityInduction() {
      // 设置是否开启重力感应
      handFunc.callChilder("setGravityInduction", {
        status: this.gravityStatus,
      });
      this.gravityStatus = !this.gravityStatus;
    },

    setAngleModel() {
      // 设置视角切换模式
      handFunc.callChilder("setAngleModel", {
        status: this.angleType,
      });
      if (this.angleType == 0) {
        this.angleType = 1;
      } else {
        this.angleType = 0;
      }
    },

    cutSceneFunc(id) {
      // 手动点击切换场景
      if (!this.isTouchscreen) {
        // 不可控屏
        return;
      }
      this.switchSence(id);
    },

    switchSence(id) {
      // 场景切换
      // console.log(id,'场景key---------------')
      handFunc.callChilder("switchSence", {
        id,
      });
    },

    showQrCode(state) {
      // 显示隐藏二维码
      handFunc.callChilder("qrCode", {
        state,
      });
    },

    clearScreen(state) {
      // 清屏
      handFunc.callChilder("clearScreen", {
        state,
      });
    },

    setView(v, h, fov, isTween) {
      // v - 水平位置 h - 垂直位置 fov - 视距 isTween - 是否平滑过渡 可选
      handFunc.callChilder("setView", {
        // 同屏
        v: v,
        h: h,
        fov: fov,
        isTween: isTween,
      });
    },

    initWebSocket() {
      //webscoket 初始化
      const wsurl = this.socketUrl + "?userNo=" + this.openid; // ws相当于http 而wss 相当于https
      this.websocket = new WebSocket(wsurl); //实例对象
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },

    websocketonopen() {
      //连接建立
      // console.log('连接建立成功')
      this.linkStatus = true;
      this.timer = setInterval(() => {
        this.doSend();
      }, 30000);
    },

    //websocket 心跳 防连接超时 WebSocket规定在一定时间内没有数据交互，就会自动断开
    doSend() {
      // let time = Date.parse(new Date()) //当前时间戳
      let data = {
        // time,
        type: "heartBeat",
        // userNo: this.openid
      };
      data = JSON.stringify(data);
      this.websocket.send(data);
      // console.log('心跳')
    },

    //1-单体静音
    //2-单体开麦
    //3-全体静音(成员管理中的全体静音)
    //4-全体开麦(成员管理中的全体开麦)
    //5-营销师退出房间，通知其他用户退出房间
    //6-请求同屏,营销师收到用户进入房间状态，延时2秒同步用户的场景和位移坐标
    //7-带看/自由模式切换
    //8-营销师踢人
    //9-后台踢人
    //10-用户进入房间或离开房间
    //11-麦克风开关
    //12-全部闭麦状态(用户新加入的时候,告诉用户全体闭麦设置)
    websocketonmessage(res) {
      //数据接收
      let self = this;
      this.websocket.send(JSON.stringify(res));
      // console.log(JSON.stringify(res),'res - 接收后端返回数据 ');
      if (res) {
        if (res.data == "连接成功") {
          return;
        }
        let receive = JSON.parse(res.data);
        if (receive.type == "syncScreen") {
          if (receive.data.messageName == "setView") {
            // 同屏 syncScreen
            // v - 水平位置 h - 垂直位置 fov - 视距 isTween - 是否平滑过渡 可选
            this.setView(
              Number(receive.data.v),
              Number(receive.data.h),
              Number(receive.data.fov),
              true
            );
          }
          this.takeLookAt();
          if (receive.data.messageName == "switchSence") {
            this.switchSence(receive.data.id);
            // for (let i = 0; i < 5; i++) {
            //     this.setAutoRoaming(false)
            // }
          }
          if (receive.data.messageName == "openLabelPoint") {
            this.openMarkPoint(receive.data.type, receive.data.id);
          }
          if (receive.data.messageName == "closeLabelPoint") {
            this.closeMarkPoint(receive.data.type);
          }
        }
        if (receive.type == "membersManagement") {
          // 房间成员管理
          if (receive.data.cmdID == 10) {
            // 用户初次加入或退出
            if (receive.data.isEnterRoom) {
              // 进入房间
              // console.log(receive.data.userNo , this.marketerNo , 'receive.data.userNo == this.marketerNo');
              if (receive.data.userNo == this.marketerNo) {
                // 营销师回到房间
                this.hintFunc(`"${receive.data.userName}"营销师进入了房间`);
              } else {
                // 某某某进入房间
                this.hintFunc(`${receive.data.userName}进入房间`);
              }
              var micStatus = null;
              if (this.webVoiceStatus == 2) {
                // 开麦
                micStatus = true;
              } else if (this.webVoiceStatus == 1) {
                // 闭麦
                micStatus = false;
              }
              var obj = {
                data: {
                  cmdID: 11,
                  micStatus: micStatus, // 麦克风状态
                  roomCode: this.userID, // 房间号
                  userNo: this.openid,
                },
                type: "membersManagement",
                userNo: this.marketerNo,
              };
              this.websocket.send(JSON.stringify(obj));
            } else {
              // 退出房间

              if (receive.data.userNo == this.marketerNo) {
                // 营销师暂时离开，马上回来
                this.hintFunc(
                  `"${receive.data.userName}"营销师暂时离开，马上回来`
                );
                this.isControlVoice = true;
              } else {
                // 某某某退出房间
                this.hintFunc(`${receive.data.userName}退出房间`);
              }
            }
          }
          if (receive.data.cmdID == 2) {
            // 单体开麦
            if (this.openid == receive.userNo) {
              this.webVoiceStatus = 2;
              this.wxSendMsgFunc({
                type: "voiceStatus",
              });
            }
          }
          if (receive.data.cmdID == 1) {
            // 单体静音
            if (this.openid == receive.userNo) {
              this.webVoiceStatus = 1;
              this.wxSendMsgFunc({
                type: "voiceStatus",
              });
            }
          }
          if (receive.data.cmdID == 8) {
            // 营销师踢人
            if (this.openid == receive.userNo) {
              this.wxSendMsgFunc({ type: "leave", key: "kick-out" });
            }
          }
          if (receive.data.cmdID == 3) {
            // 全体静音
            this.webVoiceStatus = 0;
            this.wxSendMsgFunc({
              type: "voiceStatus",
            });
            this.isControlVoice = false;
          }
          if (receive.data.cmdID == 4) {
            // 开启全体开麦
            this.webVoiceStatus = 3;
            this.wxSendMsgFunc({
              type: "voiceStatus",
            });
            this.isControlVoice = true;
          }
          if (receive.data.cmdID == 7) {
            // 是否开启自由模式
            if (receive.data.freeMode) {
              // 开启自由模式
              this.isTouchscreen = true;
              if (this.flage_staue) {
                this.hintFunc("加入房间成功");
              } else {
                this.hintFunc("营销师已开启自由模式");
              }
              // 可以转动屏幕
              this.isTouchscreen = true;
              // sessionStorage.setItem('touchscreen',true)
              if (this.touchscreenPermissions) {
                // 开启自由模式之前，存在着这控屏的权限
                // 找个状态定义起来
                this.openTou = true;
              } else {
                this.openTou = false;
              }
              this.touchscreenPermissions = false;
            } else {
              // 关闭自由模式
              // 读取本地缓存，是否可以转动屏幕
              // if(sessionStorage.getItem('touchscreen') !== null && sessionStorage.getItem('touchscreen') !== undefined){
              //   this.isTouchscreen = sessionStorage.getItem('touchscreen')
              // }else{
              this.isTouchscreen = false;
              // }
              //读取本地缓存，是否可以控屏
              // if(sessionStorage.getItem('touchscreenPermissions') !== null && sessionStorage.getItem('touchscreenPermissions') !== undefined){
              //   this.touchscreenPermissions = sessionStorage.getItem('touchscreenPermissions')
              // }else{
              // 当用户关闭自由模式之后，恢复初始化
              if (this.openTou) {
                this.touchscreenPermissions = true;
              } else {
                this.touchscreenPermissions = false;
              }
              // }
              if (this.flage_staue) {
                this.hintFunc("加入房间成功");
              } else {
                this.hintFunc("营销师已关闭自由模式");
              }
              this.switchSence(receive.data.id);
              this.setAutoRoaming(false);
            }
            this.flage_staue = false;
          }
          if (receive.data.cmdID == 12) {
            // 麦克风状态 - 用户新加入时候告诉用户全体闭麦设置
            if (receive.data.allCloseMic) {
              // 全体静音
              this.webVoiceStatus = 0;
              this.wxSendMsgFunc({
                type: "voiceStatus",
              });
              this.isControlVoice = false;
            } else {
              this.isControlVoice = true;
              if (receive.data.micStatus) {
                // 开麦
                this.webVoiceStatus = 2;
                this.wxSendMsgFunc({
                  type: "voiceStatus",
                });
              } else {
                // 闭麦
                this.webVoiceStatus = 1;
                this.wxSendMsgFunc({
                  type: "voiceStatus",
                });
              }
            }
          }
          if (receive.data.cmdID == 5) {
            // 房间解散
            this.wxSendMsgFunc({ type: "leave" });
            this.confirmLeaveFunc();
            this.hintFunc("房间已解散！", 5000);
          }
        }
        if (receive.type == "transferPermission") {
          // 带看控屏 - 权限移交
          let hostUserNo = receive.data.hostUserNo;
          if (hostUserNo.length && hostUserNo.length > 0) {
            if (this.hostUserNo == this.openid && hostUserNo != this.openid) {
              this.hintFunc("控屏权限已转移");
              // sessionStorage.setItem('touchscreen',false)
              // sessionStorage.setItem('touchscreenPermissions',false)
              this.touchscreenPermissions = false;
              this.isTouchscreen = false;
            }
            if (hostUserNo == this.openid) {
              if (this.openid == this.marketerNo) {
                this.hintFunc("控屏权限已收回");
                // sessionStorage.setItem('touchscreen',false)
                // sessionStorage.setItem('touchscreenPermissions',false)
                this.touchscreenPermissions = false;
                this.isTouchscreen = false;
              } else {
                this.hintFunc("营销师已开启你的房间控屏权限，快来滑动看看吧！");
                // sessionStorage.setItem('touchscreen',true)
                // touchscreenPermissions 有控屏的权限
                // isTouchscreen 可以自由转动视角的权限，
                // 两者是独立的 可以自由旋转，不代表可以控屏，有控屏权限，比如能自由旋转
                // sessionStorage.setItem('touchscreenPermissions',true)
                this.touchscreenPermissions = true;
                this.isTouchscreen = true;
              }
            }
            this.hostUserNo = hostUserNo;
          }
        }
        if (receive.pushType == "notifyBusy") {
          // 营销师忙碌-拒绝带看
          this.$refs.hintPopControl.init({
            type: "notifyBusy",
          });
        }
        if (receive.pushType == "notifyRoomReady") {
          // 加入带看成功-创建房间
          this.closeUserListFunc(); //隐藏营销师列表
          this.marketerNo = receive.marketerNo; // 营销师编号
          this.userID = receive.roomCode; // 房间号
          this.wxSendMsgFunc({ type: "joinVoice" });
        }
        if (receive.source == "weixin") {
          // 微信推送来的消息
          if (receive.data.quitStutas) {
            // 退出房间成功
            clearInterval(this.leaveRoomTime);
            var quitObj = {
              data: {
                cmdID: 10,
                isEnterRoom: false,
                userName: this.custName,
                userNo: this.openid,
              },
              type: "membersManagement",
              userNo: this.marketerNo,
            };
            this.websocket.send(JSON.stringify(quitObj));
            this.confirmLeaveFunc();
            if (receive.data.quitKey == "kick-out") {
              // 被踢出
              this.hintFunc("您已被营销师移出房间。", 5000);
            } else {
              this.hintFunc("您已退出远程带看！", 5000);
            }
          }
          if (receive.data.joinStutas) {
            // 加入房间成功
            clearInterval(this.joinRoomTime);
            self.iframeSrc = `${this.phonUrl}${this.initScenario}.html?app=1&isweixin=1`;
            //  document.getElementById("vrId").src = `${this.phonUrl}${this.initScenario}.html?app=1&isweixin=1`
            this.panoramaCode = `${this.phonUrl}${this.initScenario}.html?app=1&isweixin=1`;
            var joinObj = {
              data: {
                cmdID: 10,
                isEnterRoom: true,
                userName: this.custName,
                userNo: this.openid,
              },
              type: "membersManagement",
              userNo: this.marketerNo,
            };
            this.websocket.send(JSON.stringify(joinObj));
            var obj = {
              // 请求同屏
              data: {
                cmdID: "6", // 操作标识
                roomCode: this.userID, // 房间号
                userNo: this.openid, // 当前用户编号
              },
              type: "membersManagement", //  transferPermission - 权限转移
              userNo: this.marketerNo, // 指定接收对象 - 营销师
            };
            this.websocket.send(JSON.stringify(obj));
            handFunc.callChilder("setAutoRoaming", {
              status: false,
            });
            this.getUserInfo();
            this.startTimeFunc(); // 开始计时

            this.lookStatus = 2; // 带看中
            this.showSceneUser = true;
          }
          if (receive.data.voiceStutas == 2) {
            // 音频控制打开成功  webVoiceStatus
            this.webVoiceStatus = receive.data.value;
            if (receive.data.value == 2 || receive.data.value == 3) {
              // 单体开麦/全体开麦
              this.voiceImg = 1; //require('@/assets/img/voice-open.png')
              if (receive.data.value == 2 && !receive.data.us) {
                this.hintFunc("你已开麦");
              } else if (receive.data.value != 2 && !receive.data.us) {
                this.hintFunc("营销师已开启全体开麦");
              }
              // this.voiceImg = require('@/assets/img/voice-close2.png')
              var obj = {
                data: {
                  cmdID: 11,
                  micStatus: true, // 开启
                  roomCode: this.userID,
                  userNo: this.openid,
                },
                type: "membersManagement",
                userNo: this.marketerNo,
              };
              this.websocket.send(JSON.stringify(obj));
              clearInterval(this.voiceRoom);
            }
          }
          if (receive.data.voiceStutas == 1) {
            // 音频控制关闭成功
            this.webVoiceStatus = receive.data.value;
            if (receive.data.value == 1) {
              // 单个关闭
              if (this.isControlVoice) {
                // 是否可以控制声音
                this.voiceImg = 2; //require('@/assets/img/voice-close2.png')
                if (!receive.data.us) {
                  this.hintFunc("你已被闭麦");
                }
              } else {
                this.voiceImg = 3; //require('@/assets/img/voice-close3.png')
              }
              // this.voiceImg = require('@/assets/img/voice-open.png')
              var obj = {
                data: {
                  cmdID: 11,
                  micStatus: false, // 关闭
                  roomCode: this.userID,
                  userNo: this.openid,
                },
                type: "membersManagement",
                userNo: this.marketerNo,
              };
              this.websocket.send(JSON.stringify(obj));
            }
            if (receive.data.value == 0) {
              // 全体静音
              this.voiceImg = 3; //require('@/assets/img/voice-close3.png')
              this.hintFunc(
                "营销师已开启全体静音，如有疑问，你可以等营销师关闭全体静音。"
              );
            }
            clearInterval(this.voiceRoom);
          }
          if (
            receive.data.userNumberChange &&
            receive.data.userNumberChange.length &&
            receive.data.userNumberChange.length > 0
          ) {
            // 接收到房间成员变化
            this.trtcUserList = receive.data.userList;
          }
        }
      }
    },

    websocketonerror(e) {
      //连接建立失败重连
      // console.log('失败重连',e)
      this.initWebSocket();
    },

    websocketclose(e) {
      // websocket关闭
      // console.log('websocket断开连接111', e)
      this.linkStatus = false;
      clearInterval(this.timer); // 关闭心跳 把定时器清掉

      if (!this.leave) {
        // 不是主动 点击离开
        this.initWebSocket();
      } else {
        // 点击主动离开
        setTimeout(() => {
          clearInterval(this.TimeBar); // 关闭 进房计时
          this.lookStatus = 0;
          // 主动点击离开时，初始化进来的时候可以自由滑动，（最后能不能滑动看营销师给没给权限）
          // sessionStorage.setItem('touchscreen',true)
          this.isTouchscreen = true;
          this.showUserList = true;
          this.dateContent = "00:00";
          this.websocket = null;
          // this.$refs.hintPopControl.init({type: 'network'}) // socket中断提示
        }, 3000);
      }
    },

    getUserInfo() {
      // 根据营销师编号获取营销师信息
      this.$axios
        .post(
          this.requestUrl + "/api/customer/queryInfo",
          JSON.stringify({
            marketerNo: this.marketerNo,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //成功 userInfo
          let data = res.data.data;
          this.userInfo.csName = data.csName; // 营销师名称
          this.userInfo.positionName = data.positionName; // 营销师职位
          this.userInfo.lordCustomerServiceHead =
            data.lordCustomerServiceHead.replace("http://", "https://"); // 营销师头像
          this.userInfo.showName = data.showName ? data.showName : "营销师";
        })
        .catch((error) => {
          // console.log(error, ' 失败') //失败
        });
    },

    wxSendMsgFunc(obj) {
      // 推送小程序带看消息
      var data = {
        userNo: "weixin" + this.openid,
        msg: {},
      };

      if (obj.type == "voiceStatus") {
        // 控制小程序音视频状态
        var voiceCode = undefined;

        if (obj.trigger == "click") {
          if (this.webVoiceStatus == 0 || this.isControlVoice == false) {
            // 手动点击/全体静音不能触发
            this.hintFunc(`营销师已开启全体静音，如有疑问，请稍后咨询营销师`);
            return;
          } else if (this.webVoiceStatus == 1) {
            // 单体关闭音频
            voiceCode = 2;
            data.msg.us = true;
          } else if (this.webVoiceStatus == 2) {
            voiceCode = 1;
            data.msg.us = true;
          } else if (this.webVoiceStatus == 3) {
            voiceCode = 1;
            data.msg.us = true;
          }
        } else {
          if (this.webVoiceStatus == 0) {
            // 全体静音
            voiceCode = 0;
          } else if (this.webVoiceStatus == 1) {
            // 单体关闭音频
            voiceCode = 1;
          } else if (this.webVoiceStatus == 2) {
            // 单体打开音频
            voiceCode = 2;
          } else if (this.webVoiceStatus == 3) {
            // 全体打开音频
            voiceCode = 3;
          }
        }

        clearInterval(this.voiceRoom);
        data.msg.type = obj.type;
        data.msg.value = voiceCode;
        this.voiceRoom = setInterval(() => {
          this.requestVoice(data);
        }, 3000);
      } else if (obj.type == "invitationJonin") {
        // 邀请加入 - 分享
        data.msg.type = obj.type;
      } else if (obj.type == "leave") {
        // 点击 离开按钮 退出
        data.msg.type = "leave";
        if (obj.key) {
          data.msg.key = obj.key; // 主动退出还是被踢出
        }
        this.leaveRoomTime = setInterval(() => {
          this.confirmLeaveFunc(); //离开带看
          this.requestLeaveRoom(data);
        }, 3000);
      } else if (obj.type == "joinVoice") {
        // 带看请求同意后 - 加入音频房间
        data.msg.type = obj.type;
        data.msg.roomID = this.userID;
        data.msg.marketerNo = this.marketerNo;
        this.joinRoomTime = setInterval(() => {
          this.requestJoinRoom(data);
        }, 3000);
      } else if (obj.type == "notLogin") {
        // 未登录
        data.msg.type = obj.type;
      }

      //设置连接成功后的回调函数
      this.websocket.send(JSON.stringify(data));
      // console.log(JSON.stringify(data), 'JSON.stringify(data) - 推送小程序消息');
    },

    requestJoinRoom(data) {
      // 请求带看加入房间 - 定时器
      let str = JSON.stringify(data);
      this.websocket.send(str);
    },
    requestLeaveRoom(data) {
      // 请求离开房间 - 定时器
      let str = JSON.stringify(data);
      this.websocket.send(str);
    },
    requestVoice(data) {
      // 请求音频 -- 定时器
      console.log(data, "请求音频");
      let str = JSON.stringify(data);
      this.websocket.send(str);
    },
    getKeynumLive() {
      // 根据场景值获取直播
      let that = this;
      this.$axios
        .post(
          this.requestUrl +
            "/api/live/configuration/queryLiveConfigurationAndLive",
          JSON.stringify({
            keyNum: this.initScenario,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let arr = res.data.data;
          if (arr) {
            that.liveInfo = arr;
          }
          console.log("直播信息---------------------", arr, res);
        })
        .catch((error) => {
          // console.log(error, ' 失败') //失败
        });
    },
    getSceneList() {
      // 获取场景列表
      this.$axios
        .post(
          this.requestUrl + "/api/vr/getPanamaList",
          JSON.stringify({
            panoKey: this.initScenario,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //成功  scene_list
          // console.log(res , '获取场景列表');
          let arr = res.data.data;
          this.sceneTotalList = arr;
          arr.map((item) => {
            this.sceneTypeList.push({
              id: item.id,
              name: item.name,
            });
          });
          this.sceneList = this.sceneTotalList[0].scene_list;
          this.sceneType = this.sceneTypeList[0].id;
          this.scenarioId = this.sceneList[0];
        })
        .catch((error) => {
          // console.log(error, ' 失败') //失败
        });
    },

    changeSceneList(id) {
      // 切换场景
      this.sceneType = id;
      this.sceneTotalList.map((item) => {
        if (item.id == id) {
          this.sceneList = item.scene_list;
        }
      });
    },

    getmerchantInfo() {
      // 根据场景查询商家信息
      this.$axios
        .post(
          this.requestUrl + "/api/panorama/queryPanoramaAndMerchant",
          JSON.stringify({
            keyNum: this.initScenario,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //成功
          // console.log(res , '根据场景查询商家信息');
          let obj = res.data.data;
          this.merchantCode = obj.merchantCode;
          this.sceneName = obj.panoramaName;
          this.getUserList();
        })
        .catch((error) => {
          // console.log(error, ' 失败') //失败
        });
    },

    getUserList() {
      // 获取营销师列表
      this.$axios
        .post(
          this.requestUrl + "/api/customer/queryByMerchantsCode",
          JSON.stringify({
            merchantCode: this.merchantCode,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //成功
          // console.log(res , '获取营销师列表');
          let arr = res.data.data;
          arr.map((item) => {
            item.lordCustomerServiceHead = item.lordCustomerServiceHead.replace(
              "http://",
              "https://"
            );
          });
          this.userList = arr;
        })
        .catch((error) => {
          // console.log(error, '失败') //失败
        });
    },

    startLookFunc(userNo) {
      // 发起带看
      if (this.openid == "undefined" || this.openid == undefined) {
        // 未登录
        this.$refs.hintPopControl.init({ type: "notLogin" });
        // .then(res=>{
        //   this.$refs.hintPopControl.init({type: 'notLogin'})
        // })
        return false;
      }
      this.initWebSocket();
      this.marketerNo = userNo;
      this.getUserInfo();
      var panoUrl = this.panoramaCode.split("?")[0];

      let obj = {
        custName: this.custName, // 被带看人名
        custUserNo: this.custUserNo, // 被带看人编号
        openId: this.openid,
        deviveType: "XCX",
        headImageUrl: this.headImageUrl, // 被带看人头像
        marketerNo: this.marketerNo, // 营销师编号
        merchantCode: this.merchantCode, // 商家code
        panoramaName: this.sceneName, // 全景名称
        panoramaUrl: panoUrl, // 全景地址
        pushType: "CR",
      };
      this.$axios
        .post(this.requestUrl + "/api/msg/pushVrMsg", JSON.stringify(obj), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          //成功
          // console.log(res , '发起带看返回参数');
          if (res.data.code == 200) {
            if (res.data.data) {
              this.lookStatus = 1;
              this.showUserList = false;
            }
          }
        })
        .catch((error) => {
          // console.log(error, ' 失败') //失败
        });
    },

    overLookFunc() {
      // 营销师在来的路上状态 点击 结束
      this.lookStatus = 0;
      this.showSceneUser = true;
      this.overLook();
      this.websocket.close();
    },
    overLook() {
      let panoUrl = this.panoramaCode.split("?")[0];
      let obj = {
        custName: this.custName, // 被带看人名
        custUserNo: this.custUserNo, // 被带看人编号
        openId: this.openid,
        deviveType: "XCX",
        headImageUrl: this.headImageUrl, // 被带看人头像
        marketerNo: this.marketerNo, // 营销师编号
        merchantCode: this.merchantCode, // 商家code
        panoramaName: this.sceneName, // 全景名称
        panoramaUrl: panoUrl, // 全景地址
        pushType: "CL",
      };
      this.$axios
        .post(this.requestUrl + "/api/msg/pushVrMsg", JSON.stringify(obj), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          //成功
          // console.log(res , '发起带看返回参数');
          if (res.data.code == 200) {
            if (res.data.data) {
              // this.lookStatus = 1
              // this.showUserList = false
              this.hintFunc("呼叫已取消");
            }
          }
        })
        .catch((error) => {
          // console.log(error, ' 失败') //失败
        });
    },

    invitationJoninFunc() {
      // 邀请加入
      this.showShareImg = true;
      // this.wxSendMsgFunc({type:'invitationJonin'})
    },

    startTimeFunc() {
      // 开始计时
      this.timekeepingFunc();
    },
    overTimeFunc() {
      // 点击离开 - 结束计时
      this.leave = true;
      this.$refs.hintPopControl.init({ type: "leaveLook" });
    },

    confirmLeaveFunc() {
      // 确定 - 离开带看
      clearInterval(this.TimeBar);
      this.websocket.onclose();
      this.lookStatus = 0;
      this.iframeSrc = `${this.phonUrl}${this.initScenario}.html?app=0&isweixin=0`;
      // document.getElementById("vrId").src = `${this.phonUrl}${this.initScenario}.html?app=0&isweixin=0`
      //  this.panoramaCode = `${this.phonUrl}${this.initScenario}.html?app=0&isweixin=0`
      this.isTouchscreen = true;
      // sessionStorage.setItem('touchscreen',true)
      this.showSceneUser = true;
      this.dateContent = "00:00";
      this.websocket = null;
    },

    timekeepingFunc() {
      //  进入房间后 - 计时器
      var TimeBar = {
        minute: 0,
        second: 0,
        resetDate: () => {
          TimeBar.second++;
          if (TimeBar.second >= 60) {
            TimeBar.second = 0;
            TimeBar.minute = TimeBar.minute + 1;
          }
          var minute =
            TimeBar.minute < 10 ? "0" + TimeBar.minute : TimeBar.minute;
          var second =
            TimeBar.second < 10 ? "0" + TimeBar.second : TimeBar.second;
          var content = minute + ":" + second;
          this.dateContent = content;
        },
      };
      this.TimeBar = setInterval(TimeBar.resetDate, 1000);
    },

    copyRoom() {
      // 复制房间号  userID
      var input = document.createElement("input");
      input.className = "copy-hide";
      input.value = this.userID; // 房间号
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length);
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.hintFunc("复制成功", 1000);
    },
  },

  mounted() {
    var map_iframe = document.getElementById("vrId");
    map_iframe.onload = function () {
      // map_iframe.contentWindow.mouseEvent();
      // let param={
      //   app:1,
      //   isweixin:1,
      //   sceneIds:this.sceneId
      // }
      // map_iframe.contentWindow.postMessage(param,param)
    };
    if (this.userID !== "undefined") {
      // 带看中
      this.lookStatus = 2;
      this.iframeSrc = `${this.phonUrl}${this.initScenario}.html?app=1&isweixin=1&sceneIds=${this.sceneId}`;
      // document.getElementById("vrId").src = `${this.phonUrl}${this.initScenario}.html?app=1&isweixin=1&sceneIds=${this.sceneId}`
      this.setAutoRoaming(false); // 漫游控制
      this.panoramaCode = `${this.phonUrl}${this.initScenario}.html?app=1&isweixin=1&sceneIds=${this.sceneId}`;
    } else {
      // 未带看
      this.lookStatus = 0;
      this.iframeSrc = `${this.phonUrl}${this.initScenario}.html?app=0&isweixin=0&sceneIds=${this.sceneId}`;
      // document.getElementById("vrId").src = `${this.phonUrl}${this.initScenario}.html?app=0&isweixin=0&sceneIds=${this.sceneId}`
      this.panoramaCode = `${this.phonUrl}${this.initScenario}.html?app=0&isweixin=0&sceneIds=${this.sceneId}`;
    }
    if (this.userID !== "undefined") {
      // 带看中
      this.initWebSocket();
      this.startTimeFunc();

      setTimeout(() => {
        var joinObj = {
          data: {
            cmdID: 10,
            isEnterRoom: true,
            userName: this.custName,
            userNo: this.openid,
          },
          type: "membersManagement",
          userNo: this.marketerNo,
        };
        this.websocket.send(JSON.stringify(joinObj));

        var obj = {
          // 请求同屏
          data: {
            cmdID: "6", // 操作标识
            roomCode: this.userID, // 房间号
            userNo: this.openid, // 当前用户编号
          },
          type: "membersManagement", //  transferPermission - 权限转移
          userNo: this.marketerNo, // 指定接收对象 - 营销师
        };
        this.websocket.send(JSON.stringify(obj));
      }, 3000);
    } else {
      // 未带看
      //  sessionStorage.setItem('touchscreen',true)
      this.isTouchscreen = true;
    }
    // 监听ifrname滑动事件
    window.addEventListener("message", this.receiveMessage, false);
    this.getUserInfo();
    setTimeout(() => {
      // 临时 - 关闭背景音乐
      handFunc.callChilder("setMusicStatus", {
        type: 0,
        status: false,
      });
      handFunc.callChilder("setMusicStatus", {
        type: 1,
        status: false,
      });
    }, 5000);
  },
  destroyed() {
    this.confirmLeaveFunc();
    clearInterval(this.joinRoomTime);
    clearInterval(this.leaveRoomTime);
    clearInterval(this.voiceRoom);
  },
};
</script>



<style lang="less" scoped>
@import "../assets/css/home.less";
</style>